<template>
  <FastOrdersCreateAdmin
      :FO="FO"
      @saveFastOrders="saveFastOrders"
  />
</template>

<script>
  import FastOrdersCreateAdmin from "./FastOrdersCreateAdmin/FastOrdersCreateAdmin";
  import {FastOrders} from "../../models/FastOrders";
  import {fastOrdersMixin} from "../../../../../mixins/fastOrdersMixins/fastOrdersMixin";

  export default {
    name: "FastOrdersCreate",

    components: {
      FastOrdersCreateAdmin,
    },

    mixins: [
      fastOrdersMixin,
    ],

    data() {
      return {
        FO: new FastOrders(),
      }
    },

    methods: {

    }
  }
</script>

<style scoped>

</style>