<template>
  <ExpressFastOrdersCreateAdmin
      :EFO="EFO"
      @saveExpressFastOrders="saveExpressFastOrders"
  />
</template>

<script>
  import ExpressFastOrdersCreateAdmin from "./ExpressFastOrdersCreateAdmin/ExpressFastOrdersCreateAdmin";
  import {ExpressFastOrders} from "../../models/ExpressFastOrders";
  import {expressFastOrdersMixin} from "@/mixins/expressFastOrdersMixins/expressFastOrdersMixin";

  export default {
    name: "ExpressFastOrdersCreate",

    components: {
      ExpressFastOrdersCreateAdmin,
    },

    mixins: [
      expressFastOrdersMixin,
    ],

    data() {
      return {
        EFO: new ExpressFastOrders(),
      }
    },

    methods: {

    }
  }
</script>

<style scoped>

</style>